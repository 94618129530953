<template>
  <div>
    <h4>Pénalités</h4>
    <v-table :data="penalties" class="table">
      <thead slot="head">
        <th>Type de pénalité</th>
        <th>Méthode de calcule</th>
        <th>Montant</th>
        <th>Actions</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.type.designation }}</td>
          <td>{{ row.calculationMethod.name }}</td>
          <td>{{ row.amount.formated }}</td>
          <td>
            <a v-if="contract.statusPlain == 'draft'" href="javascript:void(0)" @click="editSinglePenalty(row.id)"><i class="fas fa-edit"></i></a>
            <a v-if="contract.statusPlain == 'draft'" href="javascript:void(0)" @click="deletePenalty(row.type.designation, row.id)"><i class="fas fa-trash text-danger ml-2"></i></a>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="row">
      <div class="col-md-12">
        <h5 v-if="showValidationBtn"><a @click.prevent="addNewContractSynpticClick" href="javascript:void(0)" class=""><i class="fas fa-plus-circle"></i> Ajouter</a></h5>
      </div>
    </div>
    <hr>
    <h4>Garanties</h4>

    <v-table :data="warranties" class="table">
      <thead slot="head">
        <th>Type de garantie</th>
        <th>Méthode de calcule</th>
        <th>Montant</th>
        <th>Actions</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.type.human }}</td>
          <td>{{ row.calculationMethod.human }}</td>
          <td>{{ row.amount.withCurrency }}</td>
          <td>
            <a v-if="contract.statusPlain == 'draft'" href="javascript:void(0)" @click="editSingleWarranty(row.id)"><i class="fas fa-edit"></i></a>
            <a v-if="contract.statusPlain == 'draft'" href="javascript:void(0)" @click="deleteWarranty(row.uuid)"><i class="fas fa-trash text-danger ml-2"></i></a>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="row">
      <div class="col-md-12">
        <h5 v-if="showValidationBtn && warranties.length == 0"><a @click.prevent="addNewContractWarrantyClick" href="javascript:void(0)" class=""><i class="fas fa-plus-circle"></i> Ajouter</a></h5>
      </div>
    </div>

  <!-- Add warranty Modal -->
  <Modal @close="clearContractWarrantyModal" v-model="showNewContractWarrantyModal" title="Ajouter une Garrantie" >
      <form @submit.prevent="saveNewWarranty">
        
        <div class="row">
          <div class="card-body">  
            <b-alert :show="newWarrantyForm.type.id == 'withholding_warranty'" variant="primary">Le montant de la garantie <b>sera déduit de chaque facture</b>.</b-alert>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newWarrantyForm.type"
                    track-by="id"
                    label="name"
                    :options="warrantiesTypes"
                    placeholder="Select Type"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.type.$error,
                    }"
                    :select-label="''"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div v-if="submitted && $v.newWarrantyForm.type.$error" class="invalid-feedback">
                    <span v-if="!$v.newWarrantyForm.type.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>

              <template v-if="newWarrantyForm.type.id == 'bank_warranty'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Méthode de calcule *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newWarrantyForm.calculationMethod"
                      track-by="id"
                      label="name"
                      :options="calculationTypes"
                      placeholder="Select Type"
                      :select-label="''"
                      :allow-empty="false"
                      :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.calculationMethod.$error,
                    }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.newWarrantyForm.calculationMethod.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.calculationMethod.required">Champ Obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant <span v-if="newWarrantyForm.calculationMethod.id == 'amount'">(en D.A)</span> <span v-else-if="newWarrantyForm.calculationMethod.id == 'percentage'">(en %)</span> *</label>
                    <input type="text" 
                    v-model="newWarrantyForm.amount" 
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.amount.$error,
                    }"
                    class="form-control" 
                    name="" id="">
                    <div v-if="submitted && $v.newWarrantyForm.amount.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.amount.required">Champ Obligatoire.</span>
                      <span v-if="!$v.newWarrantyForm.amount.decimal">Champ Invalide.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Date d'expiration *</label>
                    <input type="date" 
                    v-model="newWarrantyForm.expDate" 
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.expDate.$error,
                    }"
                    class="form-control" 
                    name="" id="">
                    <div v-if="submitted && $v.newWarrantyForm.expDate.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.expDate.required">Champ Obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Banque *</label>
                    <input type="text" 
                    v-model="newWarrantyForm.bankName" 
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.bankName.$error,
                    }"
                    class="form-control" 
                    name="" id="">
                    <div v-if="submitted && $v.newWarrantyForm.bankName.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.bankName.required">Champ Obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Adresse de la banque *</label>
                    <input type="text" 
                    v-model="newWarrantyForm.bankAdr" 
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.bankAdr.$error,
                    }"
                    class="form-control" 
                    name="" id="">
                    <div v-if="submitted && $v.newWarrantyForm.bankAdr.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.bankAdr.required">Champ Obligatoire.</span>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="newWarrantyForm.type.id == 'withholding_warranty'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Méthode de calcule *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newWarrantyForm.calculationMethod"
                      track-by="id"
                      label="name"
                      :options="calculationTypes"
                      placeholder="Select Type"
                      :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.calculationMethod.$error,
                      }"
                      :select-label="''"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.newWarrantyForm.calculationMethod.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.calculationMethod.required">Champ Obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant <span v-if="newWarrantyForm.calculationMethod.id == 'amount'">(en D.A)</span> <span v-else-if="newWarrantyForm.calculationMethod.id == 'percentage'">(en %)</span> *</label>
                    <input type="text" 
                    v-model="newWarrantyForm.amount" 
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newWarrantyForm.amount.$error,
                      }"
                    class="form-control" 
                    name="" id="">
                    <div v-if="submitted && $v.newWarrantyForm.amount.$error" class="invalid-feedback">
                      <span v-if="!$v.newWarrantyForm.amount.required">Champ Obligatoire.</span>
                      <span v-if="!$v.newWarrantyForm.amount.decimal">Champ Invalide.</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>            
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>


    <!-- Add penalty Modal -->
    <Modal
      v-model="showNewContractPenaltyModal"
      title="Ajouter une Pénalitée"
      @close="_reset_new_penalty_modal"
    >
      <form @submit.prevent="saveNewPenalty">
        
        <div class="row">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newPenaltyForm.type"
                    track-by="id"
                    label="designation"
                    :options="penaltieTypes"
                    placeholder="Select Type"
                    :select-label="''"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newPenaltyForm.type.$error,
                    }"
                    
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div v-if="submitted && $v.newPenaltyForm.type.$error" class="invalid-feedback">
                    <span v-if="!$v.newPenaltyForm.type.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Méthode de calcule *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newPenaltyForm.calculationMethod"
                    track-by="id"
                    label="name"
                    :options="calculationTypes"
                    placeholder="Select Type"
                    :select-label="''"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newPenaltyForm.calculationMethod.$error,
                    }"
                    
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div v-if="submitted && $v.newPenaltyForm.calculationMethod.$error" class="invalid-feedback">
                    <span v-if="!$v.newPenaltyForm.calculationMethod.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Montant *</label>
                  <input type="text" 
                  v-model="newPenaltyForm.amount" 
                  :class="{
                      'is-invalid':
                        submitted &&
                        $v.newPenaltyForm.amount.$error,
                    }"
                  class="form-control" 
                  name="" id="">
                  <div v-if="submitted && $v.newPenaltyForm.amount.$error" class="invalid-feedback">
                    <span v-if="!$v.newPenaltyForm.amount.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                <div class="form-group">
                  <label for="formrow-email-input">Description pénalité</label>
                  <ckeditor
                    v-model="newPenaltyForm.description"
                    :editor="editor"
                  ></ckeditor>
                </div>
              </div>
            </div>

            
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>
<script>
import {
  required,
  requiredIf,
  decimal
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';
import { getDropDownElementsByTypeApi } from "@/api/common";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  components: { Multiselect, ckeditor: CKEditor.component },
  import: {getDropDownElementsByTypeApi},
  props: {
    contract: {},
    penaltieTypes: {
      type: Array,
    },
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    newPenaltyForm: {
      type: {
        required,
      },
      calculationMethod: {
        required,
      },
      amount: {
        required,
      },      
    },

    newWarrantyForm: {
      type: {required},
      calculationMethod: {required},
      amount: {required, decimal},
      bankName: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.id == 'bank_warranty';
        }),
      },
      bankAdr: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.id == 'bank_warranty';
        }),
      },
      expDate: {
        required: requiredIf(function() {
          return this.newWarrantyForm.type.id == 'bank_warranty';
        }),
      },
    },
  },
  data() {
    return {
      penalties: [],
      warranties: [],
      synopticsTypes: [],
      warrantiesTypes: [
        {
          id: 'bank_warranty',
          name: 'Caution bancaire'
        },
      
        {
          id: 'withholding_warranty',
          name: 'Retenue'
        }
      ],
      calculationTypes: [
        {
          id: 'amount',
          name: 'Montant'
        },

        {
          id: 'percentage',
          name: 'Pourcentage'
        },
      ],
      editor: ClassicEditor,
      editorData: "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      submitted: false,
      newPenaltyForm: {
        type: "",
        calculationMethod: "",
        amount: "",
        description: "",
      },
      newWarrantyForm: {
        type: "",
        calculationMethod: "",
        bankName: "",
        bankAdr: "",
        amount: "",
        expDate: "",
        comments: "",
      },
      showNewContractPenaltyModal: false,
      showNewContractWarrantyModal: false,
    };
  },

  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn(){
      var show = false;
      if(this.contract){
        show = this.contract.statusPlain == 'draft' || this.user.role.id == 1;
      }
      return show;
    },
  },

  watch: {
    contractualScope() {
      this.contractualScope.forEach((element) => {
        element.enteredAmount = 0;
      });
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },

    contract(){
      if(this.contract){
        this.reloadPenaltiesTable();    
        this.reloadWarrantyTable();    
      }else{
        this.clearForm();
      }
    },
  },

  methods: {
    clearContractWarrantyModal(){
      this.submitted = false;
      this.newWarrantyForm.type = "";
      this.newWarrantyForm.calculationMethod = "";
      this.newWarrantyForm.bankName = "";
      this.newWarrantyForm.bankAdr = "";
      this.newWarrantyForm.amount = "";
      this.newWarrantyForm.expDate = "";
      this.newWarrantyForm.comments = "";
    },
    clearForm(){
        this.synoptics = [];
        this.penalties = [];
        this.warranties = [];
    },

    saveNewWarranty(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newWarrantyForm.$touch();
        if(!this.$v.newWarrantyForm.$invalid){
          let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/saveWarrantyData",
            {
              contractId: this.contract.id,
              newWarrantyForm: this.newWarrantyForm,
            }
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.saveSynopticData;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.showNewContractWarrantyModal = false;
                this.clearContractWarrantyModal();
                this.$emit("reloadPenaltiesTable", true);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
        }
    },  

    saveNewPenalty(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPenaltyForm.$touch();
        if(!this.$v.newPenaltyForm.$invalid){
          let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/savePenaltiesData",
            {
              contractId: this.contract.id,
              newPenaltyForm: this.newPenaltyForm,
            }
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.saveSynopticData;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.showNewContractPenaltyModal = false;
                this._reset_new_penalty_modal();
                this.$emit("reloadPenaltiesTable", true);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
        }
        
      // if (!this.$v.$invalid) {
      // }
    },

    _reset_new_penalty_modal(){
      this.showNewContractPenaltyModal = false;
      this.submitted = false;
      this.newPenaltyForm.type = "";
      this.newPenaltyForm.description = "";
      this.newPenaltyForm.amount = "";
      this.newPenaltyForm.calculationMethod = "";
    },

    reloadPenaltiesTable(){
      this.penalties = this.contract.penalties;
    },

    reloadWarrantyTable(){
      this.warranties = [];
      if(this.contract.warranty){
        this.warranties.push(this.contract.warranty);
      }
    },
    
    addNewContractSynpticClick() {
      this.showNewContractPenaltyModal = true;
    },

    addNewContractWarrantyClick(){
      this.showNewContractWarrantyModal = true;
    },  

    fetchContractSynpticTypes(){
      getDropDownElementsByTypeApi('contract_synoptic_type')
      .then((res) => {
          this.synopticsTypes = res.data.original.list;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    editSinglePenalty(penaltyId){
      // get service details
      var loader = this.$loading.show();
        this.$http.post("/contracts/contracts/getSinglePenaltyData",{
          penaltyId: penaltyId
        })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var penaltyRec = res.data.original.penalty;
            switch (status) {
              case 200:
                // this.$toast.success(res.data.original.msg);
                this.newPenaltyForm.type = penaltyRec.type;
                this.newPenaltyForm.calculationMethod = penaltyRec.calculationMethod;
                this.newPenaltyForm.amount = penaltyRec.amount.plain;
                this.newPenaltyForm.description = penaltyRec.comments;

                this.newPenaltyForm.isUpdate = true;
                this.newPenaltyForm.penaltyId = penaltyRec.id;
        
                this.showNewContractPenaltyModal = true;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },

    deletePenalty(synName, penaltyId){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer La pénalité: '" + synName + "' ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/contracts/contracts/deleteContractPenalty", {
              penaltyId: penaltyId,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit("reloadPenaltiesTable", true);
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editSingleWarranty(warrantyId){
      var loader = this.$loading.show();
        this.$http.post("/contracts/contracts/getSingleWarrantyData",{
          warrantyId: warrantyId
        })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var warrantyRec = res.data.original.warranty;
            switch (status) {
              case 200:
                // this.$toast.success(res.data.original.msg);
                this.newWarrantyForm.type = warrantyRec.type.obj;
                this.newWarrantyForm.calculationMethod = warrantyRec.calculationMethod.obj;
                this.newWarrantyForm.bankName = warrantyRec.extra.bankName;
                this.newWarrantyForm.bankAdr = warrantyRec.extra.bankAdr;
                this.newWarrantyForm.expDate = warrantyRec.extra.extDatePlain;
                this.newWarrantyForm.amount = warrantyRec.amount.plain;
                // this.newWarrantyForm.comments = warrantyRec.calculationMethod

                this.newWarrantyForm.isUpdate = true;
                this.newWarrantyForm.warrantyId = warrantyRec.id;
        
                this.showNewContractWarrantyModal = true;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },
    deleteWarranty(warrantyId){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer cette garantie ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/contracts/contracts/deleteContractWarranty", {
              warrantyId: warrantyId,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit("reloadPenaltiesTable", true);
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>