var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("fieldset", { attrs: { disabled: _vm.formDisabled } }, [
      _c("div", { ref: "generalInfosContainer", staticClass: "row" }, [
        _c("div", { staticClass: "card-body" }, [
          _vm.validationErrors.length > 0
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger",
                  attrs: {
                    role: "alert",
                    "aria-live": "polite",
                    "aria-atomic": "true"
                  }
                },
                [
                  _c(
                    "ul",
                    { staticStyle: { "margin-bottom": "0rem" } },
                    _vm._l(_vm.validationErrors, function(value, key, index) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(value) + " ")
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("N° Contrat *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractRef,
                      expression: "globalContractInfosForm.contractRef"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.contractRef.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: { value: _vm.globalContractInfosForm.contractRef },
                  on: {
                    blur: _vm.$v.globalContractInfosForm.contractRef.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractRef",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.contractRef.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.contractRef.required
                        ? _c("span", [
                            _vm._v("La référence du contrat est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.contractRef.maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(_vm._s(this.vrules.contractRef.maxLength))
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Désignation *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractDesignation,
                      expression: "globalContractInfosForm.contractDesignation"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.contractDesignation.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractDesignation
                  },
                  on: {
                    blur:
                      _vm.$v.globalContractInfosForm.contractDesignation.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractDesignation",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.contractDesignation.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.contractDesignation
                        .required
                        ? _c("span", [
                            _vm._v("La Désignation du contrat est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.contractDesignation
                        .maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  this.vrules.contractDesignation.maxLength
                                )
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Objet *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractObject,
                      expression: "globalContractInfosForm.contractObject"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.contractObject.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractObject
                  },
                  on: {
                    blur: _vm.$v.globalContractInfosForm.contractObject.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractObject",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.contractObject.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.contractObject.required
                        ? _c("span", [
                            _vm._v("L'objet du contrat est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.contractObject.maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(this.vrules.contractObject.maxLength)
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "formrow-inputCity" } }, [
                    _vm._v("Type de Prestation *")
                  ]),
                  _c("multiselect", {
                    class: {
                      "is-invalid":
                        _vm.submitted &&
                        _vm.$v.globalContractInfosForm.contractType.$error,
                      disabledMS: _vm.formDisabled
                    },
                    attrs: {
                      searchable: true,
                      "track-by": "id",
                      label: "designation",
                      options: _vm.contractTypes,
                      placeholder: "Select Contract Type",
                      "select-label": "",
                      "allow-empty": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleType",
                        fn: function(ref) {
                          var type = ref.type
                          return [_vm._v(_vm._s(type.designation))]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.globalContractInfosForm.contractType,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.globalContractInfosForm,
                          "contractType",
                          $$v
                        )
                      },
                      expression: "globalContractInfosForm.contractType"
                    }
                  }),
                  _vm.submitted &&
                  _vm.$v.globalContractInfosForm.contractType.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        !_vm.$v.globalContractInfosForm.contractType.required
                          ? _c("span", [_vm._v("Le Type est obligatoire.")])
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "formrow-password-input" } }, [
                    _vm._v("Prestataire *")
                  ]),
                  _c("multiselect", {
                    class: {
                      "is-invalid":
                        _vm.submitted &&
                        _vm.$v.globalContractInfosForm.contractSupplier.$error,
                      disabledMS: _vm.formDisabled
                    },
                    attrs: {
                      searchable: true,
                      "track-by": "id",
                      label: "name",
                      options: _vm.suppliers,
                      "select-label": "",
                      placeholder: "Select Supplier",
                      "allow-empty": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleType",
                        fn: function(ref) {
                          var supplier = ref.supplier
                          return [_vm._v(_vm._s(supplier.name))]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.globalContractInfosForm.contractSupplier,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.globalContractInfosForm,
                          "contractSupplier",
                          $$v
                        )
                      },
                      expression: "globalContractInfosForm.contractSupplier"
                    }
                  }),
                  _vm.submitted &&
                  _vm.$v.globalContractInfosForm.contractSupplier.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        !_vm.$v.globalContractInfosForm.contractSupplier
                          .required
                          ? _c("span", [
                              _vm._v("Le prestataire est obligatoire.")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "formrow-email-input" } }, [
                    _vm._v("Attachement "),
                    _vm.globalContractInfosForm.ov_file.fullPath != ""
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.globalContractInfosForm.ov_file.fullPath,
                              target: "_blank"
                            }
                          },
                          [_c("i", { staticClass: "fas fa-download" })]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "vue-dropzone",
                    {
                      ref: "myVueDropzone",
                      staticClass: "ebs-upload",
                      attrs: {
                        id: "dropzone",
                        "use-custom-slot": true,
                        options: _vm.dropzoneOptions
                      },
                      on: { "vdropzone-complete": _vm.afterComplete },
                      model: {
                        value: _vm.invoiceAttachment,
                        callback: function($$v) {
                          _vm.invoiceAttachment = $$v
                        },
                        expression: "invoiceAttachment"
                      }
                    },
                    [
                      _c("div", { staticClass: "dropzone-custom-content" }, [
                        _c("i", {
                          staticClass: "display-4 text-muted fas fa-upload"
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputCity" } }, [
                  _vm._v("Date début de validité *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractPeriodFrom,
                      expression: "globalContractInfosForm.contractPeriodFrom"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.contractPeriodFrom.$error
                  },
                  attrs: { id: "projectname", type: "date", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractPeriodFrom
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractPeriodFrom",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.contractPeriodFrom.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.contractPeriodFrom
                        .required
                        ? _c("span", [
                            _vm._v("La date début du contrat est obligatoire.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputState" } }, [
                  _vm._v("Date d'expiration *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractPeriodTo,
                      expression: "globalContractInfosForm.contractPeriodTo"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.contractPeriodTo.$error
                  },
                  attrs: { id: "projectname", type: "date", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractPeriodTo
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractPeriodTo",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.contractPeriodTo.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.contractPeriodTo.required
                        ? _c("span", [
                            _vm._v("La date fin du contrat est obligatoire.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputCity" } }, [
                  _vm._v("Date de signature")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractSignatureDate,
                      expression:
                        "globalContractInfosForm.contractSignatureDate"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "projectname", type: "date", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractSignatureDate
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractSignatureDate",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-lg-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputState" } }, [
                  _vm._v("Date d’entrée en vigueur")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.contractEffectiveDate,
                      expression:
                        "globalContractInfosForm.contractEffectiveDate"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { id: "projectname", type: "date", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.contractEffectiveDate
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "contractEffectiveDate",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Domiciliation Client *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.clientDomiciliation,
                      expression: "globalContractInfosForm.clientDomiciliation"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.clientDomiciliation.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.clientDomiciliation
                  },
                  on: {
                    blur:
                      _vm.$v.globalContractInfosForm.clientDomiciliation.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "clientDomiciliation",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.clientDomiciliation.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.clientDomiciliation
                        .required
                        ? _c("span", [
                            _vm._v("La Domiciliation Client est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.clientDomiciliation
                        .maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  this.vrules.clientDomiciliation.maxLength
                                )
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Représentant Client *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.clientRepresentative,
                      expression: "globalContractInfosForm.clientRepresentative"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.clientRepresentative.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.clientRepresentative
                  },
                  on: {
                    blur:
                      _vm.$v.globalContractInfosForm.clientRepresentative
                        .$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "clientRepresentative",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.clientRepresentative.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.clientRepresentative
                        .required
                        ? _c("span", [
                            _vm._v("Le Représentant Client est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.clientRepresentative
                        .maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  this.vrules.clientRepresentative.maxLength
                                )
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Domiciliation Prestataire *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.spDomiciliation,
                      expression: "globalContractInfosForm.spDomiciliation"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.spDomiciliation.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.spDomiciliation
                  },
                  on: {
                    blur: _vm.$v.globalContractInfosForm.spDomiciliation.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "spDomiciliation",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.spDomiciliation.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.spDomiciliation.required
                        ? _c("span", [
                            _vm._v(
                              "La Domiciliation Prestataire est obligatoire."
                            )
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.spDomiciliation.maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(this.vrules.spDomiciliation.maxLength)
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-email-input" } }, [
                  _vm._v("Représentant Prestataire *")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.globalContractInfosForm.spRepresentative,
                      expression: "globalContractInfosForm.spRepresentative"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.submitted &&
                      _vm.$v.globalContractInfosForm.spRepresentative.$error
                  },
                  attrs: { id: "projectname", type: "text", placeholder: "" },
                  domProps: {
                    value: _vm.globalContractInfosForm.spRepresentative
                  },
                  on: {
                    blur:
                      _vm.$v.globalContractInfosForm.spRepresentative.$touch,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.globalContractInfosForm,
                        "spRepresentative",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm.submitted &&
                _vm.$v.globalContractInfosForm.spRepresentative.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.globalContractInfosForm.spRepresentative.required
                        ? _c("span", [
                            _vm._v("Représentant Prestataire est obligatoire.")
                          ])
                        : _vm._e(),
                      _c("br"),
                      !_vm.$v.globalContractInfosForm.spRepresentative.maxLength
                        ? _c("span", [
                            _vm._v("La taille minimum du champs est: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(this.vrules.spRepresentative.maxLength)
                              )
                            ]),
                            _vm._v(" Caractères.")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("label", { attrs: { for: "formrow-inputState" } }, [
                _vm._v("Comentaires")
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.globalContractInfosForm.contractComments,
                    expression: "globalContractInfosForm.contractComments"
                  }
                ],
                staticClass: "form-control",
                attrs: { cols: "30", rows: "1" },
                domProps: {
                  value: _vm.globalContractInfosForm.contractComments
                },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.globalContractInfosForm,
                      "contractComments",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _vm.showValidationBtn
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-label btn-success float-right",
                        attrs: { type: "button" },
                        on: { click: _vm.validateGeneralInfosClick }
                      },
                      [
                        _c("i", {
                          staticClass: "bx bx-check-double label-icon"
                        }),
                        _vm._v(" Valider l'étape ")
                      ]
                    )
                  : _vm._e(),
                _vm.showValidationBtn
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn btn-light btn-label  mr-2 float-right",
                        attrs: { variant: "danger" },
                        on: { click: _vm.cancelForm }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-times-circle text-white label-icon"
                        }),
                        _vm._v(" Annuler ")
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }