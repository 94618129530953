<template>
  <div>
    <fieldset :disabled="formDisabled">
      <div class="row" ref="finacialInfosContainer">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h4>Aspects et conditions financières</h4>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Cotractuel</label>
                  <div class="input-group">
                    <input
                      type="text"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.contractualAmnt.$error,
                      }"
                      v-model="financialScopeForm.contractualAmnt"
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.contractualAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.contractualAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.contractualAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Hébergement</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('hosting')"
                      type="text"
                      v-model="financialScopeForm.hostingAmnt"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.hostingAmnt.$error,
                      }"
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.hostingAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.hostingAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.hostingAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Restauration</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('catering')"
                      type="text"
                      v-model="financialScopeForm.cateringAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.cateringAmnt.$error,
                      }"
                    />
                    
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.cateringAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.cateringAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.cateringAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Maintenance des Installations</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('maintenance_of_facilities')"
                      type="text"
                      v-model="financialScopeForm.maintenanceOfFacilitiesAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.maintenanceOfFacilitiesAmnt.$error,
                      }"
                    />
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.maintenanceOfFacilitiesAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.maintenanceOfFacilitiesAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.maintenanceOfFacilitiesAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Espace vert</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('green_space')"
                      type="text"
                      v-model="financialScopeForm.greenSpaceAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.greenSpaceAmnt.$error,
                      }"
                    />
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.greenSpaceAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.greenSpaceAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.greenSpaceAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Opérations 3D</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('threed_ops')"
                      type="text"
                      v-model="financialScopeForm.threedOpsAmnt"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.threedOpsAmnt.$error,
                      }"
                      class="form-control"
                    />
                    
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.threedOpsAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.threedOpsAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.threedOpsAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Blanchisserie & Dégraissage</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('laundry_degreasing')"
                      type="text"
                      v-model="financialScopeForm.laundryDegreasingAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.laundryDegreasingAmnt.$error,
                      }"
                    />
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                    <div v-if="submitted && $v.financialScopeForm.laundryDegreasingAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.laundryDegreasingAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.laundryDegreasingAmnt.decimal">Champs Invalide.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Entretien & Nettoyage</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('maintenance_cleaning')"
                      type="text"
                      v-model="financialScopeForm.maintenanceCleaningAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.maintenanceCleaningAmnt.$error,
                      }"
                    />
                    <div v-if="submitted && $v.financialScopeForm.maintenanceCleaningAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.maintenanceCleaningAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.maintenanceCleaningAmnt.decimal">Champs Invalide.</span>
                    </div>
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Transport du Personnel</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('personal_transport')"
                      type="text"
                      v-model="financialScopeForm.personalTransportAmnt"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.personalTransportAmnt.$error,
                      }"
                      class="form-control"
                    />
                    <div v-if="submitted && $v.financialScopeForm.personalTransportAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.personalTransportAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.personalTransportAmnt.decimal">Champs Invalide.</span>
                    </div>
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom01">Montant Transport de Marchandise</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      :disabled="disabledInput('goods_transport')"
                      type="text"
                      v-model="financialScopeForm.goodsTransportAmnt"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.financialScopeForm.goodsTransportAmnt.$error,
                      }"
                    />
                    <div v-if="submitted && $v.financialScopeForm.goodsTransportAmnt.$error" class="invalid-feedback">
                      <span v-if="!$v.financialScopeForm.goodsTransportAmnt.required">Champs Obligatoire.</span>
                      <span v-if="!$v.financialScopeForm.goodsTransportAmnt.decimal">Champs Invalide.</span>
                    </div>
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-6">
            <h4>Autres prestations</h4>
            <hr />
            <div class="row">

              <div class="col-md-6" v-for="customService in customContractualScope" v-bind:key="customService.id">
                <div class="mb-3">
                  <label for="validationCustom01">Montant {{ customService.name }}</label>
                  <div class="input-group">
                    <input
                      id="validationCustom01"
                      type="text"
                      v-model="customService.contractAmnt"
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <span
                        id="validationTooltipUsernamePrepend"
                        class="input-group-text"
                        >DA</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              v-if="showValidationBtn"
              type="button"
              @click="validateFinancialScopeClick"
              class="btn btn-label btn-success float-right"
            >
              <i class="bx bx-check-double label-icon"></i> Valider l'étape
            </button>
            <b-button v-if="showValidationBtn"  @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
                <i class="fas fa-times-circle text-white label-icon"></i> Annuler
            </b-button>
          </div>
        </div>
      </div>
    </div>
    </fieldset>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  decimal,
} from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import { mapGetters } from 'vuex';

export default {
  props: {
    contractualScope: {},
    customContractualScope: {},
    services: {},
    contract: {},
    formDisabled: {
      type: Boolean,
      default: true,
    },
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn(){
      var show = true;
      if(this.contract){
        show = this.contract.statusPlain == 'draft' || this.user.role.id == 1;
      }
      return show;
    },
  },
  watch: {
    contractualScope() {
      this.contractualScope.forEach((element) => {
        element.enteredAmount = 0;
      });
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },

    customContractualScope(){
      
    },

    contract(){
      if(this.contract){
        this.financialScopeForm.contractualAmnt = this.contract.amounts.planned_amount.plain;
        if(this.contract.amounts.planned_amount.plain == 0){
          this.financialScopeForm.contractualAmnt = "";
        }
        this.financialScopeForm.hostingAmnt = this.contract.amounts.hosting_amount.plain;
        this.financialScopeForm.cateringAmnt = this.contract.amounts.catering_amount.plain;
        this.financialScopeForm.threedOpsAmnt = this.contract.amounts.threed_ops_amnt.plain;
        this.financialScopeForm.greenSpaceAmnt = this.contract.amounts.greenspace_amnt.plain;

        this.financialScopeForm.maintenanceOfFacilitiesAmnt = this.contract.amounts.maintenance_amount.plain;
        this.financialScopeForm.personalTransportAmnt = this.contract.amounts.personal_transport_amnt.plain;
        this.financialScopeForm.goodsTransportAmnt = this.contract.amounts.goods_transport_amnt.plain;
        this.financialScopeForm.laundryDegreasingAmnt = this.contract.amounts.laundry_degreasing_amnt.plain;
        this.financialScopeForm.maintenanceCleaningAmnt = this.contract.amounts.maintenance_cleaning_amnt.plain;
        // handle custom services


        this.financialScopeForm.isUpdate = true;        
        this.financialScopeForm.id = this.contract.id;        
        this.financialScopeForm.uuid = this.contract.uuid;        

        this.formDisabled = this.contract.statusPlain != 'draft'; 
      }else{
        this.clearForm();
      }
    },
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "DA ",
        suffix: " ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showAlertsInputs: false,
      directives: { money: VMoney },
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      displayedFields: {},
      financialScopeForm: {
        contractualAmnt: "",
        hostingAmnt: "",
        cateringAmnt: "",
        greenSpaceAmnt: "",
        maintenanceOfFacilitiesAmnt: "",
        laundryDegreasingAmnt: "",
        maintenanceCleaningAmnt: "",
        threedOpsAmnt: "",
        personalTransportAmnt: "",
        goodsTransportAmnt: "",
      },
      alerts: {
        first: "",
        second: "",
        third: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },

  methods: {

    cancelForm(){
      this.$emit('cancelCurrentForm', true);
    },

    clearForm(){
        this.financialScopeForm.contractualAmnt = "";
        this.financialScopeForm.hostingAmnt = "";
        this.financialScopeForm.cateringAmnt = "";
        this.financialScopeForm.greenSpaceAmnt = "";
        this.financialScopeForm.maintenanceOfFacilitiesAmnt = "";
        this.financialScopeForm.laundryDegreasingAmnt = "";
        this.financialScopeForm.maintenanceCleaningAmnt = "";
        this.financialScopeForm.threedOpsAmnt = "";
        this.financialScopeForm.personalTransportAmnt = "";
        this.financialScopeForm.goodsTransportAmnt = "";
    },

    _sumAmountsSplitted(){
      var sum = 0;
      if(!this.disabledInput('hosting')){
        var hosting = 0;
        if(this.financialScopeForm.hostingAmnt != ""){
          hosting = this.financialScopeForm.hostingAmnt;
        }
        sum += parseFloat(hosting);
      }
      if(!this.disabledInput('green_space')){
        var greenSpaceAmnt = 0;
        if(this.financialScopeForm.greenSpaceAmnt!= ""){
          greenSpaceAmnt = this.financialScopeForm.greenSpaceAmnt;
        }
        sum += parseFloat(greenSpaceAmnt);
      }
      if(!this.disabledInput('catering')){
        var cateringAmnt = 0;
        if(this.financialScopeForm.cateringAmnt != ""){
          cateringAmnt = this.financialScopeForm.cateringAmnt;
        }
        sum += parseFloat(cateringAmnt);
      }
      if(!this.disabledInput('maintenance_of_facilities')){
        var maintenanceOfFacilitiesAmnt = 0;
        if(this.financialScopeForm.maintenanceOfFacilitiesAmnt != ""){
          maintenanceOfFacilitiesAmnt = this.financialScopeForm.maintenanceOfFacilitiesAmnt;
        }
        sum += parseFloat(maintenanceOfFacilitiesAmnt);
      }
      if(!this.disabledInput('laundry_degreasing')){
        var laundryDegreasingAmnt = 0;
        if(this.financialScopeForm.laundryDegreasingAmnt != ""){
          laundryDegreasingAmnt = this.financialScopeForm.laundryDegreasingAmnt;
        }
        sum += parseFloat(laundryDegreasingAmnt);
      }
      if(!this.disabledInput('maintenance_cleaning')){
        var maintenanceCleaningAmnt = 0;
        if(this.financialScopeForm.maintenanceCleaningAmnt != ""){
          maintenanceCleaningAmnt = this.financialScopeForm.maintenanceCleaningAmnt;
        }
        sum += parseFloat(maintenanceCleaningAmnt);
      }
      if(!this.disabledInput('threed_ops')){
        var threedOpsAmnt = 0;
        if(this.financialScopeForm.threedOpsAmnt != ""){
          threedOpsAmnt = this.financialScopeForm.threedOpsAmnt;
        }
        sum += parseFloat(threedOpsAmnt);
      }
      if(!this.disabledInput('personal_transport')){
        var personalTransportAmnt = 0;
        if(this.financialScopeForm.personalTransportAmnt != ""){
          personalTransportAmnt = this.financialScopeForm.personalTransportAmnt;
        }
        sum += parseFloat(this.financialScopeForm.personalTransportAmnt);
      }
      if(!this.disabledInput('goods_transport')){
        var goodsTransportAmnt = 0;
        if(this.financialScopeForm.goodsTransportAmnt != ""){
          goodsTransportAmnt = this.financialScopeForm.goodsTransportAmnt;
        }
        sum += parseFloat(goodsTransportAmnt);
      }
      sum = parseFloat(sum);
      if(this.customContractualScope.length > 0){
        this.customContractualScope.forEach(element => {
          var elemAmnt = 0;
          if(element.contractAmnt != "" && !isNaN(element.contractAmnt) && element.contractAmnt != undefined){
            elemAmnt = parseFloat(element.contractAmnt);
          }
          sum += elemAmnt;
        });
      }
      return sum;
    },
    _checkAmendmantAmount(contractualAmnt){
      var proceed = true;
      if(this.contract.isAmandmentContract){
        var maxAllowedAmnt = this.contract.amendmant_calculation_control._20_; 
        if(contractualAmnt > maxAllowedAmnt){
          this.$toast.error("Le montant du contrat avenant ne doit pas dépasser 20% du contrat principale.");
          proceed = false;
        }
      }
      return proceed;
    },
    _validateAmounts(){
      var amountsAreValid = true;
      var contractualAmnt = parseFloat(this.financialScopeForm.contractualAmnt);
      if(this._checkAmendmantAmount(contractualAmnt)){
        var sumAmountsSplitted = this._sumAmountsSplitted();
        var diff = Math.abs(contractualAmnt - sumAmountsSplitted);
        if(contractualAmnt != sumAmountsSplitted){
          this.$toast.error("Le système a détecter une différence de <strong>"+diff+"</strong> DA entre le montant contractuel et la répartition des montants");
          amountsAreValid = false;
        }
        return amountsAreValid;
      }
    },
    disabledInput(service){
      return this.contractualScope.filter(element => element.id == service).length == 0;
    },
    validateFinancialScopeClick() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.$v.$invalid && this._validateAmounts()) {
      let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/saveFinancialInfoStep",
            {
              contractId: this.contract.id,
              financialData: this.financialScopeForm,
              customServices: this.customContractualScope,
            }
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.savedContract;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.$emit("FinancialInfoStepSaved", true);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
}
        
      // if (!this.$v.$invalid) {
      // }
    },
  },

  validations: {
    financialScopeForm: {
      contractualAmnt: {
        required,
        decimal
      },
      hostingAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('hosting')
        }),
        decimal,
      },

      greenSpaceAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('green_space')
        }),
        decimal,
      },
      
      cateringAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('catering')
        }),
        decimal,
      },
      maintenanceOfFacilitiesAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('maintenance_of_facilities')
        }),
        decimal,
      },
      laundryDegreasingAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('laundry_degreasing')
        }),
        decimal,
      },
      maintenanceCleaningAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('maintenance_cleaning')
        }),
        decimal,
      },
      threedOpsAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('threed_ops')
        }),
        decimal,
      },
      personalTransportAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('personal_transport')
        }),
        decimal,
      },
      goodsTransportAmnt: {
        required: requiredIf(function() {
          return !this.disabledInput('goods_transport')
        }),
        decimal,
      },
    },
  },
};
</script>
