var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.contract
                ? [
                    _vm._l(_vm.contract.selectedCampsWithServices, function(
                      base
                    ) {
                      return _c(
                        "div",
                        { key: base.id, staticClass: "col-md-4" },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "mb-2",
                              staticStyle: { border: "2px solid blue" },
                              attrs: { title: base.name, tag: "article" }
                            },
                            [
                              _c("b-card-text", [
                                _vm._v(
                                  " Configurer les prestations suivantes pour la base de vie: "
                                ),
                                _c("b", [_vm._v(_vm._s(base.name))]),
                                _vm._v(": "),
                                _c(
                                  "ol",
                                  { staticStyle: { height: "100px" } },
                                  [
                                    _vm._l(base.services, function(service) {
                                      return _c("li", { key: service.id }, [
                                        _vm._v(
                                          " " + _vm._s(service.name) + " "
                                        ),
                                        service.setupDone
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-check-circle text-success bg-soft"
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "fas fa-exclamation-triangle text-warning bg-soft"
                                            })
                                      ])
                                    }),
                                    _vm._l(
                                      _vm.contract
                                        .selectedCampsWithCustomServices,
                                      function(customBase) {
                                        return [
                                          customBase.id == base.id
                                            ? _vm._l(
                                                customBase.services,
                                                function(service) {
                                                  return _c(
                                                    "li",
                                                    { key: service.id },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(service.name) +
                                                          " "
                                                      ),
                                                      service.setupDone
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fas fa-check-circle text-success bg-soft"
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fas fa-exclamation-triangle text-warning bg-soft"
                                                          })
                                                    ]
                                                  )
                                                }
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    href: "#",
                                    variant: _vm.baseSetupDone(base)
                                      ? "success"
                                      : "warning"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showPrestationsModalClick(base)
                                    }
                                  }
                                },
                                [
                                  !_vm.baseSetupDone(base)
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-exclamation-triangle"
                                      })
                                    : _c("i", {
                                        staticClass: "far fa-check-circle"
                                      }),
                                  _vm._v(" Prestations ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm._l(
                      _vm.contract.selectedCampsWithCustomServices,
                      function(customBase) {
                        return _c(
                          "div",
                          { key: customBase.id, staticClass: "col-md-4" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-2",
                                staticStyle: { border: "2px solid blue" },
                                attrs: {
                                  title: customBase.name,
                                  tag: "article"
                                }
                              },
                              [
                                _c("b-card-text", [
                                  _vm._v(
                                    " Configurer les prestations autres suivantes pour la base de vie: "
                                  ),
                                  _c("b", [_vm._v(_vm._s(customBase.name))]),
                                  _vm._v(": "),
                                  _c(
                                    "ol",
                                    { staticStyle: { height: "100px" } },
                                    _vm._l(customBase.services, function(
                                      service
                                    ) {
                                      return _c("li", { key: service.id }, [
                                        _vm._v(
                                          " " + _vm._s(service.name) + " "
                                        ),
                                        service.setupDone
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-check-circle text-success bg-soft"
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "fas fa-exclamation-triangle text-warning bg-soft"
                                            })
                                      ])
                                    }),
                                    0
                                  )
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      href: "#",
                                      variant: _vm.baseSetupDone(customBase)
                                        ? "success"
                                        : "warning"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showPrestationsModalClick(
                                          customBase
                                        )
                                      }
                                    }
                                  },
                                  [
                                    !_vm.baseSetupDone(customBase)
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle"
                                        })
                                      : _c("i", {
                                          staticClass: "far fa-check-circle"
                                        }),
                                    _vm._v(" Prestations ")
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _vm.showValidationBtn
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-label btn-success float-right",
                        attrs: { type: "button" },
                        on: { click: _vm.validateUnitPriceScopeClick }
                      },
                      [
                        _c("i", {
                          staticClass: "bx bx-check-double label-icon"
                        }),
                        _vm._v(" Valider l'étape ")
                      ]
                    )
                  : _vm._e(),
                _vm.showValidationBtn
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn btn-light btn-label  mr-2 float-right",
                        attrs: { variant: "danger" },
                        on: { click: _vm.cancelForm }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-times-circle text-white label-icon"
                        }),
                        _vm._v(" Annuler ")
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: {
            title:
              "Borderaux des prix unitaires Pour le site: " +
              this.priceListModalCampName,
            modalClass: "fullWidthModal"
          },
          model: {
            value: _vm.showLifebaseServicesPricingModal,
            callback: function($$v) {
              _vm.showLifebaseServicesPricingModal = $$v
            },
            expression: "showLifebaseServicesPricingModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Définitions des prestations & tarrifs")
                ]),
                _c("p", { staticClass: "card-title-desc" }),
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _vm.contract
                      ? [
                          _vm.contract.hasCustomServices == true
                            ? _c("other-service", {
                                attrs: {
                                  contract: _vm.priceListModalData.contract,
                                  camp: _vm.priceListModalData.base
                                },
                                on: {
                                  reloadContractServicesStatus:
                                    _vm.handleReloadContractServicesStatusEvent
                                }
                              })
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.catering
                      ? _c("restauration-tab", {
                          attrs: {
                            contract: _vm.priceListModalData.contract,
                            camp: _vm.priceListModalData.base,
                            service: "catering"
                          },
                          on: {
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.hosting
                      ? _c("hosting-tab", {
                          attrs: {
                            contract: _vm.priceListModalData.contract,
                            camp: _vm.priceListModalData.base,
                            service: "hosting"
                          },
                          on: {
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.maintenance_of_facilities
                      ? _c("maintenance-tab", {
                          attrs: {
                            contract: _vm.priceListModalData.contract,
                            camp: _vm.priceListModalData.base,
                            service: "maintenance_of_facilities"
                          },
                          on: {
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.threed_ops
                      ? _c("threedops-tab", {
                          attrs: {
                            contract: _vm.priceListModalData.contract,
                            camp: _vm.priceListModalData.base,
                            service: "threed_ops"
                          },
                          on: {
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.greenspace
                      ? _c("greenspace-tab", {
                          attrs: {
                            contract: _vm.priceListModalData.contract,
                            camp: _vm.priceListModalData.base,
                            service: "green_space"
                          },
                          on: {
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.laundry_degreasing
                      ? _c("laundry-tab")
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.maintenance_cleaning
                      ? _c("cleaning-tab")
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.personal_transport
                      ? _c("employees-transport-tab")
                      : _vm._e(),
                    _vm.priceListModalData.allowedTabs.goods_transport
                      ? _c("cargo-transport-tab")
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }