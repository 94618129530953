var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-12" },
          [
            _vm.contract
              ? [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v("Infos Générales.")
                      ]),
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v("N° Contrat:")
                              ]),
                              _vm.contract
                                ? _c("td", [_vm._v(_vm._s(_vm.contract.ref))])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v("Objet:")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.object))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v("Prestataire:")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.supplier.name))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v("Type:")
                              ]),
                              _vm.contract
                                ? _c("td", [_vm._v(_vm._s(_vm.contract.type))])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v("Montant Global:")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.contract.totalAmnt))
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v("Infos Prestataire.")
                      ]),
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(" Nom Complet: ")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.supplier.name))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(" Email: ")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.supplier.email))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(" Téléphone: ")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.supplier.phone))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(" Domaine d'activité ")
                              ]),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.contract.supplier.secteurAct
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v("Dates & Délais.")
                      ]),
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _vm._m(0),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.start_date))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _vm._m(1),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.contract.end_date))
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _vm._m(2),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.contract.contract_signature_date
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("tr", [
                              _vm._m(3),
                              _vm.contract
                                ? _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.contract.contract_effective_date
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._m(4),
                      _vm.contract
                        ? _vm._l(
                            _vm.contract.selectedCampsWithServices,
                            function(base) {
                              return _c(
                                "div",
                                { key: base.id, staticClass: "col-md-4" },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticClass: "mb-2",
                                      staticStyle: { border: "2px solid blue" },
                                      attrs: {
                                        title: "Site: " + base.name,
                                        tag: "article"
                                      }
                                    },
                                    [
                                      _c("b-card-text", [
                                        _c(
                                          "ol",
                                          { staticStyle: { height: "100px" } },
                                          [
                                            _vm._l(base.services, function(
                                              service
                                            ) {
                                              return _c(
                                                "li",
                                                { key: service.id },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(service.name) +
                                                      " "
                                                  ),
                                                  service.setupDone
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fas fa-check-circle text-success bg-soft"
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "fas fa-exclamation-triangle text-warning bg-soft"
                                                      })
                                                ]
                                              )
                                            }),
                                            _vm._l(
                                              _vm.contract
                                                .selectedCampsWithCustomServices,
                                              function(customBase) {
                                                return [
                                                  customBase.id == base.id
                                                    ? _vm._l(
                                                        customBase.services,
                                                        function(service) {
                                                          return _c(
                                                            "li",
                                                            { key: service.id },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    service.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              service.setupDone
                                                                ? _c("i", {
                                                                    staticClass:
                                                                      "fas fa-check-circle text-success bg-soft"
                                                                  })
                                                                : _c("i", {
                                                                    staticClass:
                                                                      "fas fa-exclamation-triangle text-warning bg-soft"
                                                                  })
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e()
                    ],
                    2
                  ),
                  (_vm.contract.statusPlain == "approved" ||
                    _vm.contract.statusPlain == "suspended") &&
                  !_vm.contract.well_executed
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h4", { staticClass: "card-title mb-4" }, [
                              _vm._v("Suivi Juridique du contrat.")
                            ]),
                            _c(
                              "v-table",
                              {
                                staticClass: "table",
                                attrs: { data: _vm.contract.followUps },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "body",
                                      fn: function(ref) {
                                        var displayData = ref.displayData
                                        return _c(
                                          "tbody",
                                          {},
                                          _vm._l(displayData, function(row) {
                                            return _c("tr", { key: row.id }, [
                                              _c("td", [
                                                _c("span", {
                                                  staticStyle: {
                                                    "font-size": "16px"
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      row.type.html
                                                    )
                                                  }
                                                })
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.date))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.reason))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.user))
                                              ]),
                                              _c("td", [
                                                _vm.contract.statusPlain ==
                                                "approved"
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editSingleFollowUp(
                                                              row.uuid
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-edit"
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.contract.statusPlain ==
                                                "approved"
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteFollowUp(
                                                              row.type.human,
                                                              row.uuid
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash text-danger ml-2"
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            ])
                                          }),
                                          0
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2495558890
                                )
                              },
                              [
                                _c(
                                  "thead",
                                  { attrs: { slot: "head" }, slot: "head" },
                                  [
                                    _c("th", [_vm._v("Type")]),
                                    _c("th", [_vm._v("Date")]),
                                    _c("th", [_vm._v("Motif")]),
                                    _c("th", [_vm._v("Par")]),
                                    _c("th", [_vm._v("Actions")])
                                  ]
                                )
                              ]
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("h5", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.onNewContractFollowUpBtnClick(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-plus-circle"
                                      }),
                                      _vm._v(" Ajouter")
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("hr")
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  (_vm.contract.statusPlain == "approved" ||
                    _vm.contract.statusPlain == "suspended") &&
                  !_vm.contract.well_executed
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h4", { staticClass: "card-title mb-4" }, [
                              _vm._v("Résiliation du contrat.")
                            ]),
                            _vm.contract.statusPlain == "approved"
                              ? [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "warning" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " La lettre de résiliation de contrat de prestation de service est un document grâce auquel "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            "un prestataire de service met un terme à sa relation avec un client, ou vice-versa"
                                          )
                                        ]),
                                        _vm._v(
                                          ". Une fois rédigée par l’une des parties au contrat, elle doit être signée puis envoyée à la partie cocontractante avec recommandation d’accusé de réception. Cette rédaction de la lettre répond à des exigences légales. "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm.contract.statusPlain == "approved"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success",
                                          on: { click: _vm.onTerminationClick }
                                        },
                                        [_vm._v("Résilier")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm.contract.statusPlain == "suspended"
                              ? [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "danger" } },
                                    [
                                      _c("p", [
                                        _vm._v("Le contrat: "),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.contract.ref))
                                        ]),
                                        _vm._v(" est suspendu.")
                                      ]),
                                      _c("ul", [
                                        _c("li", [
                                          _vm._v("Date de résiliation: "),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.contract.suspension.date
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("li", [
                                          _vm._v("Résilié Par: "),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.contract.suspension.by
                                                  .fullName
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("li", [
                                          _vm._v("Motif de résiliation: "),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.contract.suspension.reason
                                              )
                                            )
                                          ])
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm.contract.statusPlain == "approved"
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h4", { staticClass: "card-title mb-4" }, [
                              _vm._v("Bonne exécution.")
                            ]),
                            !_vm.contract.well_executed
                              ? [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "primary" } },
                                    [
                                      _vm._v(
                                        " Le bouton 'Bonne exécution' sera activé aprés l'expiration du contrat, il sert à: "
                                      ),
                                      _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            "Générer une facture qui contient le montant des retenues des garanties (si applicable)."
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            "Clôturer définitivement le contrat N°: "
                                          ),
                                          _c("b", [
                                            _vm._v(_vm._s(_vm.contract.ref))
                                          ]),
                                          _vm._v(".")
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm.contract.statusPlain == "fenced"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success",
                                          on: { click: _vm.onWellExecutedClick }
                                        },
                                        [_vm._v("Bonne exécution")]
                                      )
                                    : _vm._e()
                                ]
                              : [_vm._m(5)]
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm.contract.statusPlain == "approved"
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h4", { staticClass: "card-title mb-4" }, [
                              _vm._v("Suspendre le contrat.")
                            ]),
                            _c(
                              "b-alert",
                              { attrs: { show: "", variant: "warning" } },
                              [
                                _vm._v(" La "),
                                _c("b", [_vm._v("Suspension du contrat")]),
                                _vm._v(
                                  " sert a bloquer temporairement l'exécution du contrat sur le système, il ne sera pas disponible que s'il est repris. "
                                )
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                on: { click: _vm.onHoldContractBtnClick }
                              },
                              [_vm._v("Suspendre")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.contract.statusPlain == "suspended"
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("h4", { staticClass: "card-title mb-4" }, [
                              _vm._v("Reprendre le contrat.")
                            ]),
                            _c(
                              "b-alert",
                              { attrs: { show: "", variant: "warning" } },
                              [
                                _vm._v(" L'option "),
                                _c("b", [_vm._v("Reprendre le contrat")]),
                                _vm._v(
                                  " permet d'utiliser le contrat, et d'inclure la durée de la suspension dans la durée du contrat. "
                                )
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                on: { click: _vm.onResumeContractBtnClick }
                              },
                              [_vm._v("Reprendre")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _c("br"),
      _vm.contract && _vm.$can("approve_contract")
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _vm.showValidationBtn
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contractDataApproved,
                              expression: "contractDataApproved"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "checkbox",
                            id: "formrow-customCheck"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.contractDataApproved)
                              ? _vm._i(_vm.contractDataApproved, null) > -1
                              : _vm.contractDataApproved
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.contractDataApproved,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.contractDataApproved = $$a.concat([
                                      $$v
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.contractDataApproved = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.contractDataApproved = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "formrow-customCheck" }
                          },
                          [
                            _vm._v(
                              "Je confirme la conformité des données du contrat N°: "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.contract.ref))])
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _vm.showValidationBtn
                    ? _c(
                        "b-button",
                        {
                          class: !_vm.contractDataApproved ? "disabled" : "",
                          attrs: { variant: "warning" },
                          on: { click: _vm.validateContract }
                        },
                        [
                          _c("i", { staticClass: "fas fa-save" }),
                          _vm._v(" Valider le contrat")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: { title: "Suivi du contrat N° " + _vm.contract.ref },
          model: {
            value: _vm.showNewContractFollowUpModal,
            callback: function($$v) {
              _vm.showNewContractFollowUpModal = $$v
            },
            expression: "showNewContractFollowUpModal"
          }
        },
        [
          _c("form", { attrs: { action: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Type *")]),
                  _c("multiselect", {
                    attrs: {
                      searchable: true,
                      "track-by": "id",
                      label: "name",
                      options: _vm.followUpsTypes,
                      placeholder: "Select Type",
                      "select-label": "",
                      "allow-empty": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleType",
                        fn: function(ref) {
                          var type = ref.type
                          return [_vm._v(_vm._s(type.name))]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.followUpForm.type,
                      callback: function($$v) {
                        _vm.$set(_vm.followUpForm, "type", $$v)
                      },
                      expression: "followUpForm.type"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("label", { attrs: { for: "" } }, [_vm._v("Date")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.followUpForm.date,
                      expression: "followUpForm.date"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "date", name: "", id: "" },
                  domProps: { value: _vm.followUpForm.date },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.followUpForm, "date", $event.target.value)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("label", { attrs: { for: "" } }, [_vm._v("Motif")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.followUpForm.reason,
                      expression: "followUpForm.reason"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "30", rows: "10" },
                  domProps: { value: _vm.followUpForm.reason },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.followUpForm, "reason", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onFollowUpSaveBtnClick($event)
                      }
                    }
                  },
                  [_vm._v("Enregistrer")]
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Résiliation du contrat " + _vm.contract.ref },
          model: {
            value: _vm.showTerminationModal,
            callback: function($$v) {
              _vm.showTerminationModal = $$v
            },
            expression: "showTerminationModal"
          }
        },
        [
          _c("form", { attrs: { action: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Motif de résiliation")
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.terminationReason,
                      expression: "terminationReason"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "30", rows: "10" },
                  domProps: { value: _vm.terminationReason },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.terminationReason = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onConfirmTerminationClick($event)
                      }
                    }
                  },
                  [_vm._v("Confirmer")]
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Bonne exécution" },
          model: {
            value: _vm.showWellExecutedModal,
            callback: function($$v) {
              _vm.showWellExecutedModal = $$v
            },
            expression: "showWellExecutedModal"
          }
        },
        [
          _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(
              " Le bouton 'Bonne exécution' sera activé aprés l'expiration du contrat, il sert à: "
            ),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Générer une facture qui contient le montant des retenues des garanties: "
                ),
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.contract.warrantiesTotal
                        ? _vm.contract.warrantiesTotal.withCurrency
                        : "0.00 DA"
                    )
                  )
                ]),
                _vm._v(".")
              ]),
              _c("li", [
                _vm._v("Clôturer définitivement le contrat N°: "),
                _c("b", [_vm._v(_vm._s(_vm.contract.ref))]),
                _vm._v(".")
              ])
            ])
          ]),
          _c("form", { attrs: { action: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onConfirmWellExecutionClick($event)
                      }
                    }
                  },
                  [_vm._v("Confirmer")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Suspension du contrat N° " + _vm.contract.ref },
          model: {
            value: _vm.showContractHoldModal,
            callback: function($$v) {
              _vm.showContractHoldModal = $$v
            },
            expression: "showContractHoldModal"
          }
        },
        [
          _c("form", { attrs: { action: "" } }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Motif de résiliation")
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.suspensionReason,
                      expression: "suspensionReason"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "30", rows: "10" },
                  domProps: { value: _vm.suspensionReason },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.suspensionReason = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onHoldContractConfirmationBtnClick($event)
                      }
                    }
                  },
                  [_vm._v("Confirmer")]
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "row" } }, [
      _c("i", { staticClass: "bx bx-calendar mr-1 text-primary" }),
      _vm._v(" Date début de validité: ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "row" } }, [
      _c("i", { staticClass: "bx bx-calendar mr-1 text-primary" }),
      _vm._v(" Date d'expiration: ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "row" } }, [
      _c("i", { staticClass: "bx bx-calendar mr-1 text-primary" }),
      _vm._v(" Date de signature: ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { attrs: { scope: "row" } }, [
      _c("i", { staticClass: "bx bx-calendar-check mr-1 text-primary" }),
      _vm._v(" Date d’entrée en vigueur: ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h4", { staticClass: "card-title mb-4" }, [
        _vm._v("Périmètre contractuel.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4 align-center" }, [
        _c("h5", { staticClass: "text-success" }, [_vm._v("Contrat exécuté.")]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/check.png"),
            width: "100",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "col-md-3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }