var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dataset", {
    attrs: { "ds-data": _vm.contracts },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var ds = ref.ds
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 mb-2 mb-md-0" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "formrow-inputCity" } }, [
                      _vm._v("Type de Prestation *")
                    ]),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascrit:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.contractFilterForm.stdServices = []
                          }
                        }
                      },
                      [_vm._v("Réinitialiser")]
                    ),
                    _c("multiselect", {
                      staticClass: "here",
                      attrs: {
                        searchable: true,
                        multiple: true,
                        "track-by": "id",
                        label: "name",
                        options: _vm.stdServicesTypes,
                        placeholder: "Filtres",
                        "select-label": "",
                        "allow-empty": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "singleType",
                            fn: function(ref) {
                              var type = ref.type
                              return [_vm._v(_vm._s(type.name))]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.contractFilterForm.stdServices,
                        callback: function($$v) {
                          _vm.$set(_vm.contractFilterForm, "stdServices", $$v)
                        },
                        expression: "contractFilterForm.stdServices"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-md-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mt-4",
                    on: { click: _vm.filterContractsList }
                  },
                  [_vm._v("Filtrer")]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", {}, [
                  _c(
                    "table",
                    { staticClass: "table table-hover d-md-table" },
                    [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(_vm.cols, function(th) {
                            return _c(
                              "th",
                              {
                                key: th.field,
                                style: th.field == "objet" ? "width: 20%;" : ""
                              },
                              [_vm._v(" " + _vm._s(th.name) + " ")]
                            )
                          }),
                          0
                        )
                      ]),
                      _c("dataset-item", {
                        attrs: { tag: "tbody" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var rowIndex = ref.rowIndex
                                return [
                                  _c(
                                    "tr",
                                    {
                                      class: {
                                        "bg-soft-info":
                                          _vm.selectedIndex == rowIndex
                                      },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectContractFromRow(
                                            row,
                                            rowIndex
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(row.ref))]),
                                      _c("td", [_vm._v(_vm._s(row.object))]),
                                      _c("td", [
                                        _vm._v(_vm._s(row.supplier.name))
                                      ]),
                                      _c("td", [_vm._v(_vm._s(row.type))]),
                                      _c("td", [_vm._v(_vm._s(row.totalAmnt))]),
                                      _c("td", [_vm._v(_vm._s(row.startDate))]),
                                      _c("td", [_vm._v(_vm._s(row.endDate))]),
                                      _c("td", [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              row.expirationDeadline.days_html
                                            )
                                          }
                                        })
                                      ]),
                                      _c("td", [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(row.status)
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-md-row flex-column justify-content-between align-items-center"
              },
              [
                _c("dataset-show", { attrs: { "ds-show-entries": 5 } }),
                _c("dataset-pager")
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }