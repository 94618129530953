var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("fieldset", { attrs: { disabled: _vm.formDisabled } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "fieldset",
              { attrs: { disabled: !_vm.events.allowStdServicesMatrix } },
              [
                _c(
                  "div",
                  { ref: "contractualScopeContainer", staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("hr"),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-striped table-bordered mb-0 va-middle"
                          },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _c("th", [_vm._v("Prestations Core")]),
                                  _vm._l(_vm.lifebases, function(base) {
                                    return _c("th", { key: base.id }, [
                                      _vm._v(" " + _vm._s(base.name) + " ")
                                    ])
                                  })
                                ],
                                2
                              )
                            ]),
                            _c(
                              "tbody",
                              {},
                              _vm._l(_vm.services, function(service) {
                                return _c(
                                  "tr",
                                  { key: service.id },
                                  [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { width: "30%" },
                                        attrs: { scope: "row" }
                                      },
                                      [_vm._v(" " + _vm._s(service.name) + " ")]
                                    ),
                                    _vm._l(_vm.lifebases, function(base) {
                                      return _c(
                                        "td",
                                        { key: base.id },
                                        [
                                          service.enabled
                                            ? _c("b-form-checkbox", {
                                                attrs: {
                                                  switch: "",
                                                  size: "sm",
                                                  checked: _vm.campHasService(
                                                    base,
                                                    service
                                                  )
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.addServiceToLifebase(
                                                      base,
                                                      service
                                                    )
                                                  }
                                                }
                                              })
                                            : _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:void()"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-popover",
                                                            rawName:
                                                              "v-b-popover.hover",
                                                            value:
                                                              "Le module: " +
                                                              service.name +
                                                              " n'est pas pris en charge dans cette version",
                                                            expression:
                                                              "`Le module: ${service.name} n'est pas pris en charge dans cette version`",
                                                            modifiers: {
                                                              hover: true
                                                            }
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-exclamation-triangle text-warning"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            ),
            _c(
              "fieldset",
              { attrs: { disabled: !_vm.events.allowCustomServicesMatrix } },
              [
                _c(
                  "div",
                  { ref: "contractualScopeContainer", staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("hr"),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-striped table-bordered mb-0 va-middle",
                            staticStyle: { "vertical-align": "center" }
                          },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _c("th", { staticStyle: { width: "30%" } }, [
                                    _vm._v("Prestations Autre")
                                  ]),
                                  _vm._l(_vm.lifebases, function(base) {
                                    return _c("th", { key: base.id }, [
                                      _vm._v(" " + _vm._s(base.name) + " ")
                                    ])
                                  })
                                ],
                                2
                              )
                            ]),
                            _c(
                              "tbody",
                              {},
                              _vm._l(_vm.customServices, function(service) {
                                return _c(
                                  "tr",
                                  { key: service.id },
                                  [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { width: "30%" },
                                        attrs: { scope: "row" }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName: "v-b-popover.hover",
                                                value:
                                                  "CLiquer pour Modifier/Supprimer le service: " +
                                                  service.name,
                                                expression:
                                                  "'CLiquer pour Modifier/Supprimer le service: '+ service.name",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            attrs: {
                                              href: "javascript:void()"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.editCustomService(
                                                  service.uuid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(service.name))
                                            ])
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.lifebases, function(base) {
                                      return _c(
                                        "td",
                                        { key: base.id },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              switch: "",
                                              size: "sm",
                                              checked:
                                                service.camp_id == base.id
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.addCustomServiceToCamp(
                                                  base,
                                                  service
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("h4", [
                      _vm.showValidationBtn &&
                      !_vm.formDisabled &&
                      _vm.events.allowCustomServicesMatrix
                        ? _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.addNewServiceClick($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-plus-circle" }),
                              _vm._v(" Ajouter")
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            ),
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _vm.showValidationBtn
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-label btn-success float-right",
                          attrs: { type: "button" },
                          on: { click: _vm.validateContractualScopeClick }
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-check-double label-icon"
                          }),
                          _vm._v(" Valider l'étape ")
                        ]
                      )
                    : _vm._e(),
                  _vm.showValidationBtn
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "btn btn-light btn-label  mr-2 float-right",
                          attrs: { variant: "danger" },
                          on: { click: _vm.cancelForm }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-times-circle text-white label-icon"
                          }),
                          _vm._v(" Annuler ")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: { title: "Ajouter une prestation / un service" },
          on: { close: _vm.clearNewOtherServiceForm },
          model: {
            value: _vm.showNewOtherServiceModal,
            callback: function($$v) {
              _vm.showNewOtherServiceModal = $$v
            },
            expression: "showNewOtherServiceModal"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveNewContractService($event)
                }
              }
            },
            [
              _vm.newCustomServiceForm.events.usePredefined
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "formrow-email-input" } },
                            [_vm._v("Prestation/Service *")]
                          ),
                          _vm.newCustomServiceForm.events.usePredefined
                            ? _c(
                                "a",
                                {
                                  staticClass: "float-right",
                                  attrs: { href: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.toggleNewServiceForm($event)
                                    }
                                  }
                                },
                                [_vm._v("Ajouter")]
                              )
                            : _vm._e(),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.newCustomServiceForm.predefinedServiceId
                                  .$error
                            },
                            attrs: {
                              searchable: true,
                              multiple: true,
                              "track-by": "id",
                              label: "name",
                              options:
                                _vm.newCustomServiceForm.predefinedServices,
                              placeholder: "Select Service",
                              "select-label": "",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleType",
                                  fn: function(ref) {
                                    var type = ref.type
                                    return [_vm._v(_vm._s(type.name))]
                                  }
                                }
                              ],
                              null,
                              false,
                              2011112945
                            ),
                            model: {
                              value:
                                _vm.newCustomServiceForm.predefinedServiceId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newCustomServiceForm,
                                  "predefinedServiceId",
                                  $$v
                                )
                              },
                              expression:
                                "newCustomServiceForm.predefinedServiceId"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.newCustomServiceForm.events.createNew
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Type *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newCustomServiceForm.type,
                              expression: "newCustomServiceForm.type"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.newCustomServiceForm.type.$error
                          },
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.newCustomServiceForm.type },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newCustomServiceForm,
                                "type",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.newCustomServiceForm.type.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.newCustomServiceForm.type.required
                                ? _c("span", [_vm._v("Champs Obligatoire.")])
                                : _vm._e(),
                              _c("br")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Code *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newCustomServiceForm.code,
                              expression: "newCustomServiceForm.code"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.newCustomServiceForm.code.$error
                          },
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.newCustomServiceForm.code },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newCustomServiceForm,
                                "code",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.newCustomServiceForm.code.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.newCustomServiceForm.code.required
                                ? _c("span", [_vm._v("Champs Obligatoire.")])
                                : _vm._e(),
                              _c("br")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Désignation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newCustomServiceForm.name,
                              expression: "newCustomServiceForm.name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.newCustomServiceForm.name.$error
                          },
                          attrs: {
                            id: "projectname",
                            type: "text",
                            placeholder: ""
                          },
                          domProps: { value: _vm.newCustomServiceForm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newCustomServiceForm,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.newCustomServiceForm.name.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.newCustomServiceForm.name.required
                                ? _c("span", [_vm._v("Champs Obligatoire.")])
                                : _vm._e(),
                              _c("br")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Description *")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newCustomServiceForm.comments,
                              expression: "newCustomServiceForm.comments"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "projectname",
                            type: "text",
                            rows: "2",
                            placeholder: ""
                          },
                          domProps: {
                            value: _vm.newCustomServiceForm.comments
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newCustomServiceForm,
                                "comments",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12 " }, [
                  _c("div", { staticClass: "float-right" }, [
                    _vm.newCustomServiceForm.allowDeleteMode
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger ",
                            attrs: { type: "submit" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteCustomService($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-trash" }),
                            _vm._v(" Supprimer ")
                          ]
                        )
                      : _vm._e(),
                    _vm.newCustomServiceForm.events.createNew &&
                    _vm.newCustomServiceForm.events.showCancelBtn
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-warning",
                            attrs: { type: "submit" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.toggleNewServiceForm($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-window-close" }),
                            _vm._v(" Annuler ")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ml-2",
                        attrs: { type: "submit" }
                      },
                      [
                        _c("i", { staticClass: "fas fa-save" }),
                        _vm._v(" Enregistrer ")
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }