var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-alert", { attrs: { show: "", variant: "info" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("h5", [_vm._v("Montant Alloué")]),
            _vm._v(" " + _vm._s(_vm.formatAmount(_vm.allocatedAmnt)) + " ")
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("h5", [_vm._v("Montant Présenté")]),
            _vm._v(" " + _vm._s(_vm.formatAmount(_vm.sumItems())) + " ")
          ]),
          _c(
            "div",
            {
              staticClass: "col-md-4",
              class: _vm.difference() != 0 ? "text-danger" : "text-success"
            },
            [
              _c("h5", [_vm._v("Ecart")]),
              _vm._v(" " + _vm._s(_vm.formatAmount(_vm.difference())) + " ")
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }