var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("SideButtons", {
        attrs: {
          reset: _vm.resetAddBtn,
          select: _vm.disableAddBtn,
          save: _vm.items,
          contract: _vm.currentSelectedContract
        },
        on: {
          info: _vm.items,
          add: function($event) {
            return _vm.addNewContract($event)
          },
          save: _vm.items,
          edit: function($event) {
            return _vm.openEditSelectedContract($event)
          },
          confirm: _vm.items,
          validate: _vm.items,
          delete: function($event) {
            return _vm.deleteSelectedContract($event)
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" },
                          on: {
                            click: function($event) {
                              _vm.active = !_vm.active
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Liste des Contrats ")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: _vm.active,
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "div",
                          { staticClass: "table-responsive mb-0 shadow" },
                          [
                            _c("contacts-table", {
                              attrs: {
                                refreshTableEvent:
                                  _vm.shouldRefreshContractsTable
                              },
                              on: {
                                loadContractDataToFormComponent:
                                  _vm.handleLoadContractDataToFormComponent,
                                clearAllContractForms:
                                  _vm.handleClearAllContractFormsEvent
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "h4",
                  { staticClass: "card-title" },
                  [
                    _vm._v(" Fiche contrat "),
                    _vm.currentSelectedContract != null
                      ? _c("span", { staticClass: "text-primary" }, [
                          _vm._v(
                            "( Contrat N°: " +
                              _vm._s(_vm.currentSelectedContract.ref) +
                              " )"
                          )
                        ])
                      : _vm._e(),
                    _vm.currentSelectedContract != null
                      ? [
                          _vm.currentSelectedContract.isParentContract &&
                          _vm.currentSelectedContract.statusPlain == "approved"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  on: { click: _vm.addAmandmentClick }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-plus" }),
                                  _vm._v(" Ajouter un avenant")
                                ]
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c("p", { staticClass: "card-title-desc" }, [
                  _vm._v(
                    " Utiliser cette partie pour identifier, modifier, ou consulter un contrat. "
                  )
                ]),
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "p-3 text-muted" },
                    model: {
                      value: _vm.formStep,
                      callback: function($$v) {
                        _vm.formStep = $$v
                      },
                      expression: "formStep"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Infos Générales "),
                                    _vm.generalInfosSetUp
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("global-contract-infos", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            contractTypes: _vm.contractTypes,
                            suppliers: _vm.suppliers,
                            formDisabled: _vm.disableForm,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            GeneralInfoStepSaved:
                              _vm.handleGeneralInfoStepSavedEvent,
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Périmètre Contractuel "),
                                    _vm.scopeSetUp
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("contractual-scope", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            lifebases: _vm.lifebases,
                            services: _vm.stdServices,
                            formDisabled: _vm.disableForm,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            selectedContractSevricesChanged: function($event) {
                              _vm.selectedContractSevricesChangedData = $event
                            },
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent,
                            selectedContractCustomSevricesChanged: function(
                              $event
                            ) {
                              _vm.selectedContractCustomSevricesChangedData = $event
                            },
                            campBaseSelected: function($event) {
                              _vm.selectedCampsData = $event
                            },
                            ContractualScopeSaved:
                              _vm.handleContractualScopeSavedEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Aspects Financiers "),
                                    _vm.financeInfosSetUp
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("financial-scope", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            contractualScope:
                              _vm.selectedContractSevricesChangedData,
                            customContractualScope:
                              _vm.selectedContractCustomSevricesChangedData,
                            services: _vm.stdServices,
                            selectedCamps: _vm.selectedCampsData,
                            formDisabled: _vm.disableForm,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            FinancialInfoStepSaved:
                              _vm.handleFinancialInfoStepSavedEvent,
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Bordereau des prix "),
                                    _vm.priceListInfosSetUp
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("unit-price-schedule", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            UnitPriceScheduleDone:
                              _vm.handleUnitPriceScheduleDoneEvent,
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent,
                            reloadContractServicesStatus:
                              _vm.handleReloadContractServicesStatusEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Pénalités & Garantie "),
                                    _vm.penaltiesInfosSetup
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("contract-penalties", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            penaltieTypes: _vm.penaltiesTypes
                          },
                          on: {
                            reloadPenaltiesTable:
                              _vm.handleReloadPenaltiesTableEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Notifications & Alertes "),
                                    _vm.notificationsInfosSetup
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("notificationsAndAlerts", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            formDisabled: _vm.disableForm,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            notificationsInfoStepSaved:
                              _vm.handleNotificationsInfoStepSavedEvent,
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v("Évaluation "),
                                    _vm.contractApproved
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        }),
                                    _vm.currentSelectedContract.hasFollowUps
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-triangle text-warning"
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("contract-review", {
                          attrs: {
                            contract: _vm.currentSelectedContract,
                            shouldClearAllForms: _vm.shouldClearAllForms
                          },
                          on: {
                            contractApproved: _vm.handleContractApprovedEvent,
                            cancelCurrentForm: _vm.handleCancelCurrentFormEvent
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [
                                    _vm._v(" Synoptique "),
                                    _c("i", {
                                      staticClass:
                                        "fas fa-info-circle text-info"
                                    })
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("contract-synoptic", {
                          attrs: { contract: _vm.currentSelectedContract },
                          on: {
                            reloadSynopticsTable:
                              _vm.handleSynopticInfoStepSavedEvent
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "Modal",
        {
          attrs: { title: "Ajouter un contrat avenant pour le contrat N°: " },
          model: {
            value: _vm.newAmandmentContractModal.show,
            callback: function($$v) {
              _vm.$set(_vm.newAmandmentContractModal, "show", $$v)
            },
            expression: "newAmandmentContractModal.show"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.generateAmendmantContract($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Type:")]),
                        _c("multiselect", {
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "name",
                            options: _vm.newAmandmentContractModalTypes,
                            placeholder: "Select Type",
                            "select-label": "",
                            "allow-empty": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleType",
                              fn: function(ref) {
                                var type = ref.type
                                return [_vm._v(_vm._s(type.name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.newAmandmentContractModal.type,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.newAmandmentContractModal,
                                "type",
                                $$v
                              )
                            },
                            expression: "newAmandmentContractModal.type"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("label", { attrs: { for: "" } }),
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.isClosingAmnd,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal,
                                  "isClosingAmnd",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.isClosingAmnd"
                            }
                          },
                          [_vm._v("Avenant de clôture")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h6", [_vm._v("Copier les volets suivants:")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.copy
                                  .contractualScope,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal.copy,
                                  "contractualScope",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.copy.contractualScope"
                            }
                          },
                          [_vm._v("Périmètre contractuel")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.copy.financeScope,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal.copy,
                                  "financeScope",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.copy.financeScope"
                            }
                          },
                          [_vm._v("L'aspect Financier")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.copy.priceScope,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal.copy,
                                  "priceScope",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.copy.priceScope"
                            }
                          },
                          [_vm._v("Borderau des prix")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.copy
                                  .notificationsAndAlerts,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal.copy,
                                  "notificationsAndAlerts",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.copy.notificationsAndAlerts"
                            }
                          },
                          [_vm._v("Notifications & alertes")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              value: true,
                              "unchecked-value": false,
                              plain: ""
                            },
                            model: {
                              value:
                                _vm.newAmandmentContractModal.copy.synoptics,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newAmandmentContractModal.copy,
                                  "synoptics",
                                  $$v
                                )
                              },
                              expression:
                                "newAmandmentContractModal.copy.synoptics"
                            }
                          },
                          [_vm._v("Synoptique")]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary float-right",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Enregistrer ")
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }