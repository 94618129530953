<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <template v-if="contract">
          <div class="row">
            <div class="col-md-4">
              <h4 class="card-title mb-4">Infos Générales.</h4>
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">N° Contrat:</th>
                      <td v-if="contract">{{ contract.ref }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Objet:</th>
                      <td v-if="contract">{{ contract.object }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Prestataire:</th>
                      <td v-if="contract">{{ contract.supplier.name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Type:</th>
                      <td v-if="contract">{{ contract.type }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Montant Global:</th>
                      <td v-if="contract">
                        <b>{{ contract.totalAmnt }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-4">
              <h4 class="card-title mb-4">Infos Prestataire.</h4>
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">
                        Nom Complet:
                      </th>
                      <td v-if="contract">{{ contract.supplier.name }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Email:
                      </th>
                      <td v-if="contract">{{ contract.supplier.email }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Téléphone:
                      </th>
                      <td v-if="contract">{{ contract.supplier.phone }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Domaine d'activité
                      </th>
                      <td v-if="contract">
                        {{ contract.supplier.secteurAct }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-4">
              <h4 class="card-title mb-4">Dates & Délais.</h4>
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <i class="bx bx-calendar mr-1 text-primary"></i> Date
                        début de validité:
                      </th>
                      <td v-if="contract">{{ contract.start_date }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i class="bx bx-calendar mr-1 text-primary"></i> Date
                        d'expiration:
                      </th>
                      <td v-if="contract">{{ contract.end_date }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i class="bx bx-calendar mr-1 text-primary"></i> Date de
                        signature:
                      </th>
                      <td v-if="contract">
                        {{ contract.contract_signature_date }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i class="bx bx-calendar-check mr-1 text-primary"></i>
                        Date d’entrée en vigueur:
                      </th>
                      <td v-if="contract">
                        {{ contract.contract_effective_date }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Périmètre contractuel.</h4>
            </div>
            <template v-if="contract">
              <div
                class="col-md-4"
                v-for="base in contract.selectedCampsWithServices"
                v-bind:key="base.id"
              >
                <b-card
                  :title="'Site: ' + base.name"
                  tag="article"
                  style="border: 2px solid blue"
                  class="mb-2"
                >
                  <b-card-text>
                    <ol style="height: 100px">
                      <li
                        v-for="service in base.services"
                        v-bind:key="service.id"
                      >
                        {{ service.name }}
                        <i
                          class="fas fa-check-circle text-success bg-soft"
                          v-if="service.setupDone"
                        ></i>
                        <i
                          class="fas fa-exclamation-triangle text-warning bg-soft"
                          v-else
                        ></i>
                      </li>

                      <template
                        v-for="customBase in contract.selectedCampsWithCustomServices"
                      >
                        <template v-if="customBase.id == base.id">
                          <li
                            v-for="service in customBase.services"
                            v-bind:key="service.id"
                          >
                            {{ service.name }}
                            <i
                              class="fas fa-check-circle text-success bg-soft"
                              v-if="service.setupDone"
                            ></i>
                            <i
                              class="fas fa-exclamation-triangle text-warning bg-soft"
                              v-else
                            ></i>
                          </li>
                        </template>
                      </template>
                    </ol>
                  </b-card-text>
                </b-card>
              </div>
            </template>
          </div>

          <!-- Mise en demeure & mise en garde -->
          <div class="row mt-2" v-if="(contract.statusPlain == 'approved' || contract.statusPlain == 'suspended') && !contract.well_executed">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Suivi Juridique du contrat.</h4>
              <v-table :data="contract.followUps" class="table">
                <thead slot="head">
                  <th>Type</th>
                  <th>Date</th>
                  <th>Motif</th>
                  <th>Par</th>
                  <th>Actions</th>
                </thead>
                <tbody slot="body" slot-scope="{ displayData }">
                  <tr v-for="row in displayData" :key="row.id">
                    <td> <span style="font-size: 16px;" v-html="row.type.html"></span></td>
                    <td>{{ row.date }}</td>
                    <td>{{ row.reason }}</td>
                    <td>{{ row.user }}</td>
                    <td>
                      <a v-if="contract.statusPlain == 'approved'" href="javascript:void(0)" @click="editSingleFollowUp(row.uuid)"><i class="fas fa-edit"></i></a>
                      <a v-if="contract.statusPlain == 'approved'" href="javascript:void(0)" @click="deleteFollowUp(row.type.human, row.uuid)"><i class="fas fa-trash text-danger ml-2"></i></a>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <div class="row">
                <div class="col-md-12">
                  <h5><a @click.prevent="onNewContractFollowUpBtnClick" href="javascript:void(0)" class=""><i class="fas fa-plus-circle"></i> Ajouter</a></h5>
                </div>
              </div>
              <hr>
            </div>
          </div>
          <!-- <hr /> -->
          <div class="row mt-2" v-if="(contract.statusPlain == 'approved' || contract.statusPlain == 'suspended') && !contract.well_executed">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Résiliation du contrat.</h4>
              <template v-if="contract.statusPlain == 'approved'">  
                <b-alert show variant="warning">
                  <p>
                    La lettre de résiliation de contrat de prestation de service est un document grâce auquel <b>un prestataire de service met un terme à sa relation avec un client, ou vice-versa</b>. Une fois rédigée par l’une des parties au contrat, elle doit être signée puis envoyée à la partie cocontractante avec recommandation d’accusé de réception. Cette rédaction de la lettre répond à des exigences légales.
                  </p> 
                </b-alert>
                <button class="btn btn-success" @click="onTerminationClick" v-if="contract.statusPlain == 'approved'">Résilier</button>
              </template>
              <template v-if="contract.statusPlain == 'suspended'">  
                <b-alert show variant="danger">
                  <p>Le contrat: <b>{{ contract.ref }}</b> est suspendu.</p> 
                  <ul>
                    <li>Date de résiliation: <b>{{ contract.suspension.date }}</b></li>
                    <li>Résilié Par: <b>{{ contract.suspension.by.fullName }}</b></li>
                    <li>Motif de résiliation: <b>{{ contract.suspension.reason }}</b></li>
                  </ul>
                </b-alert>
              </template>
            </div>
          </div>
          <!-- <hr /> -->
          <div class="row mt-2" v-if="contract.statusPlain == 'approved'">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Bonne exécution.</h4>
              <template v-if="!contract.well_executed">  
                <b-alert show variant="primary">
                  Le bouton 'Bonne exécution' sera activé aprés l'expiration du contrat, il sert à: 
                  <ul>
                    <li>Générer une facture qui contient le montant des retenues des garanties (si applicable).</li>
                    <li>Clôturer définitivement le contrat N°: <b>{{ contract.ref }}</b>.</li>  
                  </ul>  
                </b-alert>

                <button class="btn btn-success" @click="onWellExecutedClick" v-if="contract.statusPlain == 'fenced'">Bonne exécution</button>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-md-4 align-center">
                    <h5 class="text-success">Contrat exécuté.</h5>
                    <img src="@/assets/images/check.png" width="100" alt="">
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </template>
            </div>
          </div>

          <!-- Suspension du contrat -->
          <!-- 
            - Arrêter temporairement le contrat pour une durée limitée
            - le status du contrat va être modifié
            - a la reprise du contrat, la date fin du contrat va être calculée
           -->
           <div class="row mt-2" v-if="contract.statusPlain == 'approved'">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Suspendre le contrat.</h4>
                <b-alert show variant="warning">
                  La <b>Suspension du contrat</b> sert a bloquer temporairement l'exécution du contrat sur le système, il ne sera pas disponible que s'il est repris.
                </b-alert>
                <button class="btn btn-warning" @click="onHoldContractBtnClick">Suspendre</button>
              
            </div>
          </div>

          <div class="row mt-2" v-if="contract.statusPlain == 'suspended'">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Reprendre le contrat.</h4>
                <b-alert show variant="warning">
                  L'option <b>Reprendre le contrat</b> permet d'utiliser le contrat, et d'inclure la durée de la suspension dans la durée du contrat.
                </b-alert>
                <button class="btn btn-warning" @click="onResumeContractBtnClick">Reprendre</button>
              
            </div>
          </div>
        </template>
      </div>
    </div>
    <br />
    <div class="row" v-if="contract && $can('approve_contract')">
      <div class="col-lg-12">
        <div class="form-group" v-if="showValidationBtn">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              v-model="contractDataApproved"
              class="custom-control-input"
              id="formrow-customCheck"
            />
            <label class="custom-control-label" for="formrow-customCheck"
              >Je confirme la conformité des données du contrat N°:
              <b>{{ contract.ref }}</b>
            </label>
          </div>
        </div>
        <div>
          <b-button
            v-if="showValidationBtn"
            :class="!contractDataApproved ? 'disabled' : ''"
            @click="validateContract"
            variant="warning"
          >
            <i class="fas fa-save"></i> Valider le contrat</b-button
          >
        </div>
      </div>
    </div>

    <!-- Follow up modal -->
    <Modal v-model="showNewContractFollowUpModal" :title="'Suivi du contrat N° '+ contract.ref" >
      <form action="">
        <div class="row">
          <div class="col-md-4">
            <label for="">Type *</label>
            <multiselect
              :searchable="true"
              v-model="followUpForm.type"
              track-by="id"
              label="name"
              :options="followUpsTypes"
              placeholder="Select Type"
              :select-label="''"
              :allow-empty="false"
             
            >
              <template slot="singleType" slot-scope="{ type }">{{
                type.name
              }}</template>
            </multiselect>
          </div>
          <div class="col-md-4">
            <label for="">Date</label>
            <input type="date" v-model="followUpForm.date" class="form-control" name="" id="">
          </div>
          <div class="col-md-12">
            <label for="">Motif</label>
            <textarea v-model="followUpForm.reason" class="form-control" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" @click.prevent="onFollowUpSaveBtnClick">Enregistrer</button>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-model="showTerminationModal" :title="'Résiliation du contrat '+ contract.ref" >
      <form action="">
        <div class="row">
          <div class="col-md-12">
            <label for="">Motif de résiliation</label>
            <textarea v-model="terminationReason" class="form-control" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" @click.prevent="onConfirmTerminationClick">Confirmer</button>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-model="showWellExecutedModal" title="Bonne exécution" >
      <b-alert show variant="warning">
        Le bouton 'Bonne exécution' sera activé aprés l'expiration du contrat, il sert à: 
        <ul>
          <li>Générer une facture qui contient le montant des retenues des garanties: <b>{{ contract.warrantiesTotal ? contract.warrantiesTotal.withCurrency : '0.00 DA' }}</b>.</li>
          <li>Clôturer définitivement le contrat N°: <b>{{ contract.ref }}</b>.</li>  
        </ul>  
      </b-alert>
      <form action="">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" @click.prevent="onConfirmWellExecutionClick">Confirmer</button>
          </div>
        </div>
      </form>
    </Modal>

    <Modal v-model="showContractHoldModal" :title="'Suspension du contrat N° '+ contract.ref" >
      <form action="">
        <div class="row">
          <div class="col-md-12">
            <label for="">Motif de résiliation</label>
            <textarea v-model="suspensionReason" class="form-control" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" @click.prevent="onHoldContractConfirmationBtnClick">Confirmer</button>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import { mapGetters } from 'vuex';

export default {
  props: {
    contract: null,
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  components: {Multiselect},
  data() {
    return {
      contractDataApproved: false,
      showWellExecutedModal: false,
      showTerminationModal: false,
      showNewContractFollowUpModal: false,
      showContractHoldModal: false,
      suspensionReason: "",
      terminationReason: "",
      followUps: [],
      followUpForm: {
        type: "",
        date: "",
        reason: "",
      },
      followUpsTypes: [
        {
          id: 'caution',
          name: 'Mise en garde',
        },
        {
          id: 'notice',
          name: 'Mise en demeure',
        },
      ]
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn() {
      console.log(this.user);
      var show = true;
      if (this.contract) {
        show = this.contract.statusPlain == "draft" || this.user.role.id == 1;
      }
      return show;
    },
    formDisabled() {
      var disabled = false;
      if (this.contract) {
        disabled = this.contract.statusPlain != "draft";
      }
      return disabled;
    },
  },

  watch: {
    shouldClearAllForms() {
      if (this.shouldClearAllForms) {
        this.clearForm();
      }
    },
  },
  methods: {
    editSingleFollowUp(id){
      var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/getSingleFollowUpDetails", {
                fwId: id,
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.followUpForm.uuid = res.data.original.data.uuid;
                    this.followUpForm.type = res.data.original.data.type;
                    this.followUpForm.date = res.data.original.data.date;
                    this.followUpForm.reason = res.data.original.data.reason;
                    this.showNewContractFollowUpModal = true;
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
    },
    deleteFollowUp(name, id){
      Swal.fire({
          title:"Êtes-vous sûr de Supprimer: " + name + "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Confirmer!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/deleteFollowUp", {
                fwId: id,
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
    },
    onNewContractFollowUpBtnClick(){
      this.showNewContractFollowUpModal = true;
    },
    onFollowUpSaveBtnClick(){
      var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/saveFollowUp", {
                contId: this.contract.uuid,
                data: this.followUpForm
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.showNewContractFollowUpModal = false;
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
    },
    onHoldContractConfirmationBtnClick(){
      Swal.fire({
          title:"Êtes-vous sûr de confirmer la suspension du Contrat N°: '" +
            this.contract.ref +
            "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Confirmer!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/suspend", {
                contId: this.contract.uuid,
                suspensionReason: this.suspensionReason
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.showContractHoldModal = false;
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
    },

    onResumeContractBtnClick(){
        Swal.fire({
          title:"Êtes-vous sûr de confirmer la reprise du Contrat N°: '" +
            this.contract.ref +
            "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Confirmer!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/resume", {
                contId: this.contract.uuid,
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
    },

    onConfirmTerminationClick(){
      Swal.fire({
          title:"Êtes-vous sûr de confirmer la résiliation du Contrat N°: '" +
            this.contract.ref +
            "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Confirmer!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/terminate", {
                contId: this.contract.uuid,
                terminationReason: this.terminationReason
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.showTerminationModal = false;
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
    },

    onConfirmWellExecutionClick(){
      Swal.fire({
          title:"Êtes-vous sûr de confirmer la bonne exécution du Contrat N°: '" +
            this.contract.ref +
            "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Confirmer!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/wellExecuted", {
                contId: this.contract.uuid,
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.showWellExecutedModal = false;
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
    },
    clearForm() {
      this.contract = null;
    },

    onTerminationClick(){
      this.showTerminationModal = true;
    },

    onWellExecutedClick(){
      this.showWellExecutedModal = true;
    },

    onHoldContractBtnClick(){
      this.showContractHoldModal = true;
    },

    _checkContractData() {
      var checked = true;
      if (!this.contract.general_infos_setup) {
        this.$toast.error(
          "Veuillez vérifier les informations générales du contrat."
        );
        checked = false;
      }

      if (!this.contract.scope_setup) {
        this.$toast.error("Veuillez vérifier le périmètre contractuel.");
        checked = false;
      }

      if (!this.contract.financial_infos_setup) {
        this.$toast.error(
          "Veuillez vérifier les Aspects Financiers du contrat."
        );
        checked = false;
      }

      if (!this.contract.financial_infos_setup) {
        this.$toast.error(
          "Veuillez vérifier les Aspects Financiers du contrat."
        );
        checked = false;
      }

      if (!this.contract.pricelist_infos_setup) {
        this.$toast.error(
          "Veuillez vérifier le Bordereau des prix du contrat."
        );
        checked = false;
      }

      if (!this.contract.notifications_infos_setup) {
        this.$toast.error(
          "Veuillez vérifier les Notifications & Alertes du contrat."
        );
        checked = false;
      }

      return checked;
    },
    validateContract() {
      if (this.contractDataApproved && this._checkContractData()) {
        Swal.fire({
          title:
            "Êtes-vous sûr de valider le Contrat N°: '" +
            this.contract.ref +
            "' ?",
          text: "Vous ne pourrez pas annuler cela!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Valider!",
        }).then((result) => {
          if (result.value) {
            var loader = this.$loading.show();
            this.$http
              .post("/contracts/contracts/validateContract", {
                contId: this.contract.id,
              })
              .then((res) => {
                loader.hide();
                var status = res.data.original.status;
                switch (status) {
                  case 200:
                    this.$toast.success(res.data.original.msg);
                    this.$emit("contractApproved", true);
                    // this.reloadSynopticsTable();
                    break;

                  case 500:
                    this.$toast.warning(res.data.original.msg);
                    break;
                }
              })
              .catch((error) => {
                loader.hide();
                this.$toast.error(error.message);
              })
              .finally(() => {});
          }
        });
      }
    },
  },
};
</script>
