<template>
  <div>
    <fieldset :disabled="formDisabled">
      <div class="row">
      <div class="col-md-4">
        <h4>Dépassement Montants</h4>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-success">
              <label for="validationCustom01">1ère Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.firstAmntAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.firstAmntAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.firstAmntAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.firstAmntAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.firstAmntAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.firstAmntAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.firstAmntAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.firstAmntAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-warning">
              <label for="validationCustom01">2ème Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.secondAmntAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.secondAmntAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.secondAmntAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.secondAmntAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.secondAmntAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.secondAmntAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.secondAmntAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.secondAmntAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-danger">
              <label for="validationCustom01">3ème Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.thirdAmntAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.thirdAmntAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.thirdAmntAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.thirdAmntAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.thirdAmntAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.thirdAmntAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.thirdAmntAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.thirdAmntAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <h4>Dépassement Quantités</h4>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-success">
              <label for="validationCustom01">1ère Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.firstQtyAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.firstQtyAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.firstQtyAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.firstQtyAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.firstQtyAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.firstQtyAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.firstQtyAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.firstQtyAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-warning">
              <label for="validationCustom01">2ème Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.secondQtyAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.secondQtyAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.secondQtyAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.secondQtyAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.secondQtyAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.secondQtyAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.secondQtyAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.secondQtyAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-danger">
              <label for="validationCustom01">3ème Alerte (%)</label>
              <input
                id="validationCustom01"
                v-model="alerts.thirdQtyAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.thirdQtyAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.thirdQtyAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.thirdQtyAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.thirdQtyAlert.maxLength">Taille maximale: <b>5 caractères</b>.</span>
                <span v-if="!$v.alerts.thirdQtyAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.thirdQtyAlert.minValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
                <span v-if="!$v.alerts.thirdQtyAlert.maxValue">Champ Invalide <b>(Le pourcentage doit être entre 0% et 100%)</b>.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <h4>Expiration du contrat</h4>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-success">
              <label for="validationCustom01">1ère Alerte (Jours)</label>
              <input
                id="validationCustom01"
                v-model="alerts.firstExpiryAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.firstExpiryAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.firstExpiryAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.firstExpiryAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.firstExpiryAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.firstExpiryAlert.minValue">Champ Invalide .</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-warning">
              <label for="validationCustom01">2ème Alerte (Jours)</label>
              <input
                id="validationCustom01"
                v-model="alerts.secondExpiryAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.secondExpiryAlert.$error,
                }"
              />
              <div v-if="submitted && $v.alerts.secondExpiryAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.secondExpiryAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.secondExpiryAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.secondExpiryAlert.minValue">Champ Invalide.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 input-danger">
              <label for="validationCustom01">3ème Alerte (Jours)</label>
              <span v-b-popover.hover="'Aprés la 3ème alerte, le système va envoyer des notifications chaque jour.'">
                <i class="fas fa-exclamation-triangle text-warning"></i>
              </span>
              <input
                id="validationCustom01"
                v-model="alerts.thirdExpiryAlert"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.alerts.thirdExpiryAlert.$error,
                }"
              />
              
              <div v-if="submitted && $v.alerts.thirdExpiryAlert.$error" class="invalid-feedback">
                <span v-if="!$v.alerts.thirdExpiryAlert.required">Champ Obligatoire.</span>
                <span v-if="!$v.alerts.thirdExpiryAlert.decimal">Champ Invalide.</span>
                <span v-if="!$v.alerts.thirdExpiryAlert.minValue">Champ Invalide.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </fieldset>

    <div class="row">
      <div class="col-md-12">
        <button
          v-if="showValidationBtn"
          type="button"
          @click="validateAlertsClick"
          class="btn btn-label btn-success float-right"
        >
          <i class="bx bx-check-double label-icon"></i> Valider l'étape
        </button>
        <b-button v-if="showValidationBtn"  @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
            <i class="fas fa-times-circle text-white label-icon"></i> Annuler
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  decimal,
  minValue,
  maxValue,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
export default {
  props: {
    contractualScope: {},
    services: {},
    contract: {},
    formDisabled: {
      type: Boolean,
      default: true,
    },
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn(){
      var show = true;
      if(this.contract){
        show = this.contract.statusPlain == 'draft' || this.user.role.id == 1;
      }
      return show;
    },
  },
  validations: {
    alerts: {
      firstAmntAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      secondAmntAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      thirdAmntAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },

      firstQtyAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      secondQtyAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      thirdQtyAlert: {
        required,
        decimal,
        maxLength: maxLength(5),
        minValue: minValue(0),
        maxValue: maxValue(100),
      },

      firstExpiryAlert: {
        required,
        decimal,
        minValue: minValue(0),
      },
      secondExpiryAlert: {
        required,
        decimal,
        minValue: minValue(0),
      },
      thirdExpiryAlert: {
        required,
        decimal,
        minValue: minValue(0),
      },
      
    },
  },
  watch: {
    contract() {
      if (this.contract) {
        this.alerts.firstAmntAlert = this.contract.alerts.amnts.first;
        this.alerts.secondAmntAlert = this.contract.alerts.amnts.second;
        this.alerts.thirdAmntAlert = this.contract.alerts.amnts.third;
        
        this.alerts.firstQtyAlert = this.contract.alerts.qty.first;
        this.alerts.secondQtyAlert = this.contract.alerts.qty.second;
        this.alerts.thirdQtyAlert = this.contract.alerts.qty.third;
        
        this.alerts.firstExpiryAlert = this.contract.alerts.expiry.third;
        this.alerts.secondExpiryAlert = this.contract.alerts.expiry.third;
        this.alerts.thirdExpiryAlert = this.contract.alerts.expiry.third;


        this.alerts.isUpdate = true;
        this.alerts.id = this.contract.id;
        this.alerts.uuid = this.contract.uuid;

        this.formDisabled = this.contract.statusPlain != 'draft'; 
      } else {
        this.clearForm();
      }
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },
  },
  data() {
    return {
      submitted: false,
      alerts: {
        firstAmntAlert: "",
        secondAmntAlert: "",
        thirdAmntAlert: "",
        firstQtyAlert: "",
        secondQtyAlert: "",
        thirdQtyAlert: "",
        firstExpiryAlert: "",
        secondExpiryAlert: "",
        thirdExpiryAlert: "",
      },
    };
  },

  methods: {

    cancelForm(){
      this.$emit('cancelCurrentForm', true);
    },

    clearForm(){
        this.alerts.firstAmntAlert = "";
        this.alerts.secondAmntAlert = "";
        this.alerts.thirdAmntAlert = "";
        this.alerts.firstQtyAlert = "";
        this.alerts.secondQtyAlert = "";
        this.alerts.thirdQtyAlert = "";
    },

    validateAlertsClick() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if(!this.$v.$invalid){
        let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/saveAlertsData",
            {
              contractId: this.contract.id,
              alertsData: this.alerts,
            }
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.savedContract;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.$emit("notificationsInfoStepSaved", contract);
                this.$v.$reset();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }

        
    },
  },
};
</script>
