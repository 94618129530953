var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-tab", {
    staticClass: "border-0",
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c("span", { staticClass: "d-inline-block d-sm-none" }, [
              _c("i", { staticClass: "fas fa-home" })
            ]),
            _c("span", { staticClass: "d-none d-sm-inline-block" }, [
              _vm._v("Entretien & Nettoyage")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }